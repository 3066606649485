import Mapper from '../models/mapper/Mappers';
import { RANK, RANK_TO_DISPLAY } from '../../utils/constants/api_constants';


export const copyToClipboard = (text, competitionData) => {
  const dataToCopy = getCopyDatafromCompetitionData(competitionData);

  return navigator.clipboard.writeText(dataToCopy)
    .then(() => {
      console.log("Text copied to clipboard:", dataToCopy);  // Log actual copied text
    })
    .catch((err) => {
      console.error("Failed to copy text to clipboard:", err);
    });
};

export const getCopyDatafromCompetitionData = (competitionData) => {
  console.log("Competition Data:", competitionData);

  let dateAndSection = ""

  let dataToCopy = "";

  if (competitionData.length === 0) {
    return dataToCopy;
  }

  const competitionDate = competitionData[0].competition.competitionDate;
  const sectionDescription = competitionData[0].competition.section.sectionDesc;
  const sectionCode = competitionData[0].competition.section.sectionCode;

  dataToCopy += `${competitionDate} - ${sectionDescription} - ${sectionCode} Results\n`;
  dataToCopy += `Congratulations to all the participants for their hard work and dedication \n`;

  for (let i = 0; i < competitionData.length; i++) {
    const participation = competitionData[i];
    const rank = participation.rank;


    if (rank === RANK_TO_DISPLAY.COMMENDED || rank === RANK_TO_DISPLAY.HIGHLY_COMMENDED || rank === RANK_TO_DISPLAY.N_A) {
      continue;  // Skip this iteration for certain ranks
    }




    // const competitionDate = participation.competition.competitionDate;
    // const sectionDescription = participation.competition.section.sectionDesc;
    // const sectionCode = participation.competition.section.sectionCode;
    const competitorName = participation.competitor.competitorName;

    dataToCopy += ` - ${competitorName}: ${rank}\n`;
  }

  return dataToCopy;
};



export const prepareFacebookShare = (url, message) => {
  return {
    url: url,
    quote: message,
  };
};