import React, { useState } from "react";


import * as Constants from '../../utils/constants';
import { validateEmail, validateName, getTodayDate } from '../../utils/validation';
import { convertDateinAuFormat } from '../../utils/dateUtils';


import '../../assets/css/form.css';

import CompetitorRepository from '../../domain/repositories/CompetitorRepository';
import Competitor from '../../domain/models/CompetitorModel';
import CertificateRepository from '../../domain/repositories/CertificateRepository';
import Mapper from '../../domain/models/mapper/Mappers';
import Select from 'react-select';
import { getRank, getCertificateType } from '../../utils/commonUtils';
import Certificate from '../../domain/models/CertificateModel';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';




function OneOffCertificate() {
    const [competitorName, setCompetitorName] = useState("");
    const [certificateTypeOption, setCertificateTypeOptions] = useState(getCertificateType());
    const [certificateTypeSelectedOption, setCertiifcateTypeSelectedOption] = useState({ key: 'Normal', value: 'Normal' });

    const [trophyName, setTrophyName] = useState("");

    const [competitionDate, setCompetitionDate] = useState("");
    const [sectionCode, setSectionCode] = useState("");
    const [sectionDesc, setSectionDesc] = useState("");

    const [placeOptions, setPlaceOptions] = useState(getRank());
    const [placeSelectedOption, setPlaceSelectedOption] = useState([]);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);


    const certificateRepository = new CertificateRepository();

    const handleCertificateTypeSelectChange = certificateTypeSelectedOption => {
        console.log("Here: certificateTypeSelectedOption ", certificateTypeSelectedOption)
        setCertiifcateTypeSelectedOption(certificateTypeSelectedOption);

    };

    const handleplaceSelectChange = placeSelectedOption => {
        setPlaceSelectedOption(placeSelectedOption);

    };

    const handleDateChange = (date) => {

        setSelectedDate(date)
        const formattedDate = date.toLocaleDateString('en-AU').replace(/\//g, '/');
        setCompetitionDate(formattedDate)
    };


    const handleFormSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        setError("");
        setSuccess("")

        try {

            const errors = [];

            if (!validateName(competitorName)) {
                errors.push("Please fill all the necessary fields");
            }


            if (errors.length > 0) {
                setError(errors);
                setIsSubmitting(false);

            } else {

                const certificateNanme = competitorName
                const certificatePlace = Mapper.mapUserDisplayRankToDb(placeSelectedOption.label)
                const certitificateSectionCode = sectionCode
                const certificateSectionDesc = sectionDesc
                const awardedFor = `Class ${certitificateSectionCode} - ${certificateSectionDesc}`
                const certificateCompetitionDate = competitionDate


                const certificateTrophyName = trophyName
                let forTrophy = false
                if (certificateTypeSelectedOption.value == 'Trophy') {
                    forTrophy = true
                }


                const certificateData = new Certificate(certificateNanme, awardedFor, certificateCompetitionDate, certificatePlace, forTrophy, certificateTrophyName)

                const certificateResponseData = await certificateRepository.generateCertificateFromAWSLambdaServerlessMulitplePdf(certificateData, true)


                // const certificateResponseData = await certificateRepository.generateCertificateFromAWSLambda(certificateData)

                const pdfURL = certificateResponseData.pdfUrl
                console.log('PDF URI:', pdfURL)


                if (pdfURL) {
                    window.open(pdfURL, "_blank");
                }

                setIsSubmitting(false);
                setSuccess("")
                // setCompetitorName('')
                // setCompetitionDate('')
                // setSectionCode('')
                // setTrophyName('')
                // setSectionDesc('')

            }
        } catch (error) {
            console.error(error);
            setIsSubmitting(false);
            setError(Constants.SOMETHING_WENT_WRONG);
        }
    };

    return (
        <div>
            <h2>One Off Certificate</h2>

            <form className="form-container" onSubmit={handleFormSubmit}>
                <div className="form-group">
                    <label>
                        Competitor Name
                        <input type="text" value={competitorName} onChange={(e) => setCompetitorName(e.target.value)} />
                    </label>
                </div>



                <div className="form-group">
                    <label>
                        Certificate Type
                        <Select value={{
                            value: certificateTypeSelectedOption.key,
                            label: certificateTypeSelectedOption.value,
                        }} options={certificateTypeOption.map(option => ({
                            value: option.key,
                            label: option.value
                        }))} onChange={handleCertificateTypeSelectChange} />
                    </label>

                </div>
                {certificateTypeSelectedOption.value == 'Trophy' &&


                    <div className="form-group">
                        <label>
                            Trophy Name
                            <input type="text" value={trophyName} onChange={(e) => setTrophyName(e.target.value)} />
                        </label>
                    </div>}


                <div className="form-group">
                    <label>
                        Section Code
                        <input type="text" value={sectionCode} onChange={(e) => setSectionCode(e.target.value)} />
                    </label>
                </div>
                <div className="form-group">
                    <label>
                        Section Desc
                        <input type="text" value={sectionDesc} onChange={(e) => setSectionDesc(e.target.value)} />
                    </label>
                </div>

                <div className="form-group">
                    <label htmlFor="datePicker">Competition Date</label>

                    {/* <DatePicker selected={selectedDate} onChange={handleDateChange} /> */}

                    <DatePicker style={{ 'text-align': 'center' }}
                        id="datePicker"
                        selected={selectedDate}
                        onChange={handleDateChange}
                        dateFormat="dd/MM/yyyy"
                        // placeholderText="Competition Date"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        className="datepicker-input"
                    />

                </div>

                <div className="form-group">

                    <Select placeholder="Place" value={placeSelectedOption} options={placeOptions.map(option => ({
                        value: option.key,
                        label: option.value
                    }))} onChange={handleplaceSelectChange} />

                </div>



                <div className="form-group">

                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? 'Please wait...' : 'Create'}
                    </button>

                </div>
                {isSubmitting && <div className="spinner"></div>}


                {error && <p className="error-message ">{error}</p>}
                {success && <p className="success-message ">{success}</p>}


            </form>
        </div>
    );
}

export default OneOffCertificate;
