// export const API_BASE_URL = 'https://staging.backend.eisteddfod.hosted.enterprises';
export const API_BASE_URL = 'https://backend.codes.hosted.enterprises';

export const TOKEN = "RtKU1kZrqG7o6bI8mUiy9hr61N4dXWrH"

export const PAGE_LIMIT = {
  DEFAULT: 10,
  PARTICIPATES_IN: 50,
  PARTICIPATES_IN_INCREMENT_100: 100,
  PARTICIPATES_IN_INCREMENT_200: 200,

};

// export const DEFAULT_PAGE_LIMIT = 10;
// export const DEFAULT_PAGE_PARTICIPATION = 50;


export const API_ENDPOINTS_ITEMS = {
  COMPETITION: 'items/competition',
  COMPETITOR: 'items/competitor',
  CATEGORY: 'items/category',
  SESSION: 'items/session',
  CATEGORY_SESSION: 'items/category_session',
  SECTION: 'items/section',
  PARTICIPATES_IN: 'items/participates_in'
};

export const API_ENDPOINTS_OBJECT = {
  COMPETITION: 'competition',
  COMPETITOR: 'competitor',
  CATEGORY: 'category',
  SESSION: 'session',
  CATEGORY_SESSION: 'category_session',
  SECTION: 'section',
  PARTICIPATES_IN: '/participates_in'
};


// API FIELDS
//STATUS
export const API_FIELDS_STATUS = {
  STATUS: 'status',
  PUBLISHED: 'published'
}

//COMPETITOR
export const API_FIELDS_COMPETITOR = {
  COMPETITOR_ID: 'competitor_id',
  COMPETITOR_NAME: 'competitor_name',
  COMPETITOR_FIRST_NAME: 'competitor_first_name',
  COMPETITOR_LAST_NAME: 'competitor_last_name',
  COMPETITOR_EMAIL: 'competitor_email',
  COMPETITOR_DOB: 'competitor_dob'
}

//COMPETITION
export const API_FIELDS_COMPETITION = {
  COMPETITION_DATE: 'competition_date',
  COMPETITION_STATUS: 'competition_status'
}

//SECTION
export const API_FIELDS_SECTION = {
  SECTION_ID: 'section_id',
  SECTION_DESC: 'section_desc',
  SECTION_CODE: 'section_code'
}

//PARTICIPATES IN
export const API_FIELDS_PARTICIPATES_IN = {
  PARTICIPATES_IN_ID: 'participates_in_id',
  RANK: 'rank',
  ORDER_NO: 'order_no'
}

//CSV COLUMN
export const CSV_COLUMNS = {
  COMPETITORS_NAME: 'Competitors Name',
  SECTION_CODE: 'Section Code',
  SECTION_DESC: 'Section Description',
  COMPETITOR_ORDER_NO: 'Competitor Order Number',
  COMPETITION_DATE: 'Competition Date',
  COMPETITOR_EMAIL_ADDRESS: 'Contact Email Address'
}

// RANK
export const RANK = {
  FIRST: 'FIRST PLACE',
  SECOND: 'SECOND PLACE',
  THIRD: 'THIRD PLACE',
  HIGHLY_COMMENDED: 'HIGHLY COMMENDED',
  ENCOURAGEMENT_AWARD: 'ENCOURAGEMENT AWARD',
  COMMENDED: 'COMMENDED',
  N_A: 'N/A',
}
// RANK
export const RANK_TO_DISPLAY = {
  FIRST: '1st',
  SECOND: '2nd',
  THIRD: '3rd',
  HIGHLY_COMMENDED: 'HC',
  COMMENDED: 'COM',
  ENCOURAGEMENT_AWARD: 'EA',
  N_A: 'N/A',
}


// COMPETITION STATUS
export const COMPETITION_STATUS = {
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
}