class Certificate {


    constructor(awardedTo = null, awardedfor = null, competitionDate = null, rank = null, forTrophy = false, trophyName = null, certificateLayout = null, certificateType = null) {

        this.awardedTo = awardedTo;
        this.awardedfor = awardedfor;
        this.competitionDate = competitionDate;
        this.rank = rank;
        this.forTrophy = forTrophy;
        this.trophyName = trophyName;
        this.certificateLayout = certificateLayout;
        this.certificateType = certificateType;

    }


}

export default Certificate;