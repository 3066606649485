export function getRank() {
  const jsonList = { "empty": "", "1": "1st", "2": "2nd", "3": "3rd", "4": "HC", "5": "EA", "0": "N/A" };
  // const rank=[["empty",""],["1","1st"],["2","2nd"],["3","3rd"],["4","HC"],["5","EA"],["0","N/A"]]
  // const options = Object.entries(jsonList).map(([value, label]) => ({
  //     value,
  //     label
  //   }));

  const options = [
    { key: 'empty', value: '' },
    { key: '1', value: '1st' },
    { key: '2', value: '2nd' },
    { key: '3', value: '3rd' },
    { key: '4', value: 'HC' },
    { key: '5', value: 'COM' },
    { key: '0', value: 'N/A' },
  ];
  return options
}


export function getCertificateType() {

  const options = [
    { key: 'Normal', value: 'Normal' },
    { key: 'Trophy', value: 'Trophy' },
  ];
  return options
}

